var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-demo-view", staticStyle: { padding: "1rem" } },
    [
      _c(
        "div",
        {
          staticStyle: {
            background: "#FFFFFF",
            "padding-left": "20px",
            "margin-bottom": "10px",
          },
        },
        [
          _vm.reload
            ? _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.jump },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _vm.tabs[0].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[0].title, name: "0" },
                      })
                    : _vm._e(),
                  _vm.tabs[1].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[1].title, name: "1" },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "scrollContent",
          staticClass: "scroll-content recordContent",
          style:
            "overflow-x: hidden; overflow-y: auto;height:" +
            _vm.contentStyleObj.height,
          on: { scroll: _vm.onScroll },
        },
        [
          _c(
            "el-row",
            { staticClass: "substanceName" },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "padding-left": "15px" },
                  attrs: { span: 24 },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.drugGroupData.name) +
                        "(id:" +
                        _vm._s(_vm.drugGroupData.id) +
                        ")"
                    ),
                  ]),
                  _vm._l(_vm.classifyIcons, function (item, index) {
                    return _c("code-icon", {
                      key: index,
                      staticStyle: { "margin-left": "5px" },
                      attrs: { iconCode: item, size: 26 },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[0].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("包含药物")])]
                  ),
                  _vm.drugGroupData.dkbDrugGroupMappingList.length > 0
                    ? _c(
                        "el-col",
                        { staticStyle: { "padding-left": "30px" } },
                        [
                          _c(
                            "ul",
                            _vm._l(
                              _vm.drugGroupData.dkbDrugGroupMappingList,
                              function (item, index) {
                                return _c(
                                  "li",
                                  { key: index, staticClass: "substanceLi" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.toDrugInfo(item)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.drugName))]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    : _c("el-col", [
                        _c("span", { staticClass: "noDataClass" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[1].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("药物组分类")])]
                  ),
                  _vm.typeDataList.length > 0
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: { span: 24 },
                        },
                        _vm._l(_vm.typeDataList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "fl classify-li",
                              staticStyle: {
                                display: "inline-block",
                                margin: "0 0px 5px 10px",
                                float: "left",
                              },
                            },
                            [
                              _c("el-tree", {
                                staticClass: "classify-tree",
                                attrs: {
                                  data: item,
                                  props: _vm.defaultProps,
                                  "empty-text": "暂无数据",
                                  "node-key": "id",
                                  "expand-on-click-node": false,
                                  "default-expand-all": "",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c("el-col", [
                        _c("span", { staticClass: "noDataClass" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }