<template>
  <div class="custom-demo-view" style="padding: 1rem;">
    <div style="background: #FFFFFF;padding-left: 20px;margin-bottom: 10px;">
      <el-tabs v-if="reload" v-model="tabIndex" @tab-click="jump">
        <el-tab-pane :label="tabs[0].title" v-if="tabs[0].isShow" name="0"></el-tab-pane>
        <el-tab-pane :label="tabs[1].title" v-if="tabs[1].isShow" name="1"></el-tab-pane>
      </el-tabs>
    </div>
    <div ref="scrollContent" class="scroll-content recordContent" @scroll="onScroll" :style="'overflow-x: hidden; overflow-y: auto;height:' + contentStyleObj.height">
      <el-row class="substanceName">
        <el-col :span="24" style="padding-left:15px;">
          <span>{{ drugGroupData.name }}(id:{{ drugGroupData.id }})</span>
          <code-icon
            v-for="(item, index) in classifyIcons"
            :key="index"
            :iconCode="item"
            :size="26"
            style="margin-left: 5px;" />
        </el-col>
      </el-row>
      <!-- 包含药物 -->
      <div :ref="tabs[0].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>包含药物</span>
          </el-col>
          <el-col style="padding-left:30px;" v-if="drugGroupData.dkbDrugGroupMappingList.length>0">
            <ul>
              <li class="substanceLi" v-for="(item,index) in drugGroupData.dkbDrugGroupMappingList" :key="index">
                <span @click="toDrugInfo(item)">{{ item.drugName }}</span>
              </li>
            </ul>
          </el-col>
          <el-col v-else>
            <span class="noDataClass">暂无数据</span>
          </el-col>
        </el-row>
      </div>
      <!-- 药物组分类 -->
      <div :ref="tabs[1].refName" class="scroll-item">
        <el-row class="substanceStructure">
            <el-col :span="24" class="substanceTitle">
              <span>药物组分类</span>
            </el-col>
            <el-col :span="24" v-if="typeDataList.length>0" style="margin-left: 20px;">
              <div
                v-for="(item,index) in typeDataList"
                :key="index"
                class="fl classify-li"
                style="display:inline-block;margin: 0 0px 5px 10px;float: left;">
                <el-tree
                  :data="item"
                  :props="defaultProps"
                  empty-text="暂无数据"
                  node-key="id"
                  class="classify-tree"
                  :expand-on-click-node="false"
                  default-expand-all>
                </el-tree>
              </div>
            </el-col>
          <el-col v-else>
            <span class="noDataClass">暂无数据</span>
          </el-col>
          </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  getDrugCategoryTree
} from '@/api/dkm/substance'
import {
  info
} from '@/api/dkm/drugGroup'
export default {
  name: 'drugGroupSearch',
  components: {Treeselect },
  data() {
    return {
      classifyIcons: [], // 分类图标
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      mineStatusValue: [],
      isTreeSelect: false,
      typeValue: null,
      typeDataList: [],
      drugGroupData: {
        dkbDrugGroupMappingList: []
      },
      reload: true,
      tabIndex: '0',
      contentStyleObj: {
        height: '100px'
      },
      tabs: [
        {
          isShow: true,
          title: '包含药物',
          refName: 'setOneRef'
        },
        {
          isShow: true,
          title: '药物组分类',
          refName: 'setTwoRef'
        }
      ],
      scrollY: 0
    }
  },
  activated() {
    this.classifyIcons = []
    this.getHight()
    window.addEventListener('resize', this.getHight)
    const id = this.$route.params && this.$route.params.id
    if (id) {
      this.getDrugGroupInfo(id)
        this.getDrugCategoryTree(id)
    }
    this.$nextTick(()=> {
      document.querySelector('.recordContent').scrollTop = this.scrollY
    })
  },
  beforeRouteEnter (to, from, next) {
    if (to.name === 'drugGroupSearch') {
      next(vm => {
        const scrollContent = vm.$refs.scrollContent
        // 记录滚动高度
        scrollContent.scrollTop = vm.$route.meta.scroll || 0
        vm.scrollY = vm.$route.meta.scroll
      })
    } else {
      next(vm => {
        const scrollContent = vm.$refs.scrollContent
        // 不记录滚动高度
        scrollContent.scrollTop = 0
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (from.name === 'drugGroupSearch') {
      const scrollContent = this.$refs.scrollContent
      this.$route.meta.scroll = scrollContent.scrollTop
    }
    next()
  },
  methods: {
    fetchData() {

    },
    // 跳转到药物查看页面
    toDrugInfo(item){
      const durgId = item.drugId
      this.$router.push('/drug/search/' + durgId)
    },
    /** 转换剂型树形数据结构 */
    normalizerTreeNode(node) {
      if (!(node.children && node.children.length > 0)) {
        delete node.children
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      }
    },
    /*根据药物组id获取药物组明细*/
    getDrugGroupInfo(id) {
      var that = this
      info(id).then(response => {
        this.drugGroupData = response.data
      })
    },
    getDrugCategoryTree(id) {
      let obj = {}
      obj.dataType = 2 // 药物组
      obj.dataId = id
      getDrugCategoryTree(obj).then(response => {
        let typeDataList = response.data || []
        let arr = this.classifyDataHandle(typeDataList)
        this.typeDataList = arr
        this.classifyIcons = [...new Set(this.classifyIcons)]
      })
    },
    // 处理分类的数据
    classifyDataHandle(typeDataList) {
      let arr = []
      for (let item of typeDataList) {
        if (!item.children) {
          item.children = []
          item.label = item.name
          item.value = item.id
          item.valueId = item.id
          this.classifyIcons.push(item.icon)
        } else if (item.children && item.children.length > 0) {
          this.treeselectDeep(item, item.children)
        }
        arr.push([item])
      }
      return arr
    },
    treeselectDeep(parent, item) {
      if (Array.isArray(item)) {
        // 数组
        for (let i = 0; i < item.length; i++) {
          if (item[i].children) {
            this.treeselectDeep(item[i], item[i].children)
            parent.valueId = item[i].valueId
            parent.label = item[i].label
          } else {
            parent.valueId = item[i].id
            parent.label = item[i].name
            item[i].icon && this.classifyIcons.push(item[i].icon)
          }
        }
      } else if (item?.constructor === Object) {
        // 对象
        if (item.children && !Array.isArray(item)) {
          this.a(item, item.children)
        } else if (!item.children && !Array.isArray(item)) {
          parent.valueId = parent.children[0].id
          parent.label = parent.children[0].name
        }
      }
    },
    refresh() {
      this.reload = false
      this.$nextTick(() => (this.reload = true))
    },
    // tab click
    jump(index, info) {
      let target = document.querySelector('.scroll-content')
      let scrollItems = document.querySelectorAll('.scroll-item')
      // 判断滚动条是否滚动到底部
      if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
        this.tabIndex = index.index.toString()
      }
      let totalY = scrollItems[index.index].offsetTop - scrollItems[0].offsetTop // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)
      let distance = document.querySelector('.scroll-content').scrollTop // 滚动条距离滚动区域顶部的距离
      // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)
      // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次
      // 计算每一小段的距离
      let step = totalY / 50
      if (totalY > distance) {
        smoothDown(document.querySelector('.scroll-content'))
      } else {
        let newTotal = distance - totalY
        step = newTotal / 50
        smoothUp(document.querySelector('.scroll-content'))
      }

      // 参数element为滚动区域
      function smoothDown(element) {
        if (distance < totalY) {
          distance += step
          element.scrollTop = distance
          setTimeout(smoothDown.bind(this, element), 10)
        } else {
          element.scrollTop = totalY
        }
      }

      // 参数element为滚动区域
      function smoothUp(element) {
        if (distance > totalY) {
          distance -= step
          element.scrollTop = distance
          setTimeout(smoothUp.bind(this, element), 10)
        } else {
          element.scrollTop = totalY
        }
      }
    },
    // 滚动条滚动
    onScroll(e) {
      let scrollItems = document.querySelectorAll('.scroll-item')
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop - 100
        if (judge) {
          this.tabIndex = i.toString()
          break
        }
      }
    },
    getHight() {
      this.contentStyleObj.height = (window.innerHeight - 190) + 'px'
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.getHight)
  },
}
</script>
<style lang="scss">
.custom-demo-view {
  background: #EBEFF7;
  .el-form-item{
    line-height: 20px!important;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 0px!important;
  }

  .el-form-item__label{
    line-height: 30px!important;
  }
  .el-form-item__content{
    line-height: 30px!important;
  }
  .el-tabs__header{
    margin-bottom: 0px!important;
  }
  .classify-li {
    margin: 10px !important;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  .classify-tree {
    border: 1px solid #638BF7;
    border-radius: 15px;
    padding: 0 5px;
    min-height: 52px;
  }
  .el-tree-node__content:hover, .el-tree-node__content:focus {
    background-color: transparent !important;
  }
  .el-tree-node__content {
    background-color: transparent !important;
  }
  .el-tree-node .is-current {
    background-color: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
.noDataClass{
  width: 80px;
  height: 30px;
  background: #E6ECFE;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: center;
  line-height: 30px;
  display: inline-block;
  margin-left: 30px;
}
.substanceName{
  height: 40px;
  line-height: 40px;
  background: #FFFFFF;
  box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
  border-radius: 4px;
  span{
    font-size: 16px;
    color: #333333;
    font-weight: 600!important;
  }
}
.el-icon-info{
  color: #E99B00;
  font-size: 16px;
  margin-right:5px;
}
.substanceStructure{
  height: 40px;
  height: auto!important;
  min-height: 40px;
  background: #FFFFFF;
  box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
  border-radius: 4px;
  margin: 10px 0px;
  padding-bottom: 20px;
}
.substanceLi{
  cursor: pointer;
}
.substanceTitle{
  font-size: 16px;
  font-weight: 600!important;
  padding:16px 0px 5px 19px;
  color: #333333;
}
ul,li{ padding:0;margin:0;list-style:none}
li{
  float: left;
  margin-right: 5px;
  height: 30px;
  line-height: 30px;
  background: #E6ECFE;
  border-radius: 3px;
  padding:0px 10px;
}
.substanceTable{
  padding:10px 20px 0px;
  margin-left:10px;
}
</style>

